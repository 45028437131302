import React from 'react';
import { Parallax } from 'react-parallax';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1130px;
  margin: 0 auto;
  padding: ${props => (props.padding === 'md' ? '100px 30px' : '150px 30px')};

  text-align: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 70px 30px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: ${props => (props.padding === 'md' ? '70px 30px' : '35px 30px')};
  }
`;

const HeadingLgText = styled.h2`
  color: #ffffff;
  font-size: 62px;
  font-weight: 100;
  letter-spacing: 1.24px;
  margin-bottom: 62px;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 35px;
    margin-bottom: 20px;
  }
`;

const HeadingSmText = styled.h2`
  color: #4f289a;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.32px;
  margin-bottom: 36px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

const ParagraphText = styled.p`
  color: #ffffff;
  font-size: 40px;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.86px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    font-size: 30px;
  } 
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 24px;
  }
`;

const StyledParallax = styled(Parallax)`
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

class ParallaxBlock extends React.Component {
  static HeadingLg = ({ children }) => (
    <HeadingLgText>{children}</HeadingLgText>
  );

  static HeadingSm = ({ children }) => (
    <HeadingSmText>{children}</HeadingSmText>
  );

  static Paragraph = ({ children }) => (
    <ParagraphText>{children}</ParagraphText>
  );

  render() {
    const { background, padding } = this.props;

    return (
      <StyledParallax bgImage={background} strength="300">
        <Container padding={padding}>
          {React.Children.map(this.props.children, child =>
            React.cloneElement(child)
          )}
        </Container>
      </StyledParallax>
    );
  }
}

export default ParallaxBlock;
